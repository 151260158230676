var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.Wrapper,{attrs:{"position":_vm.position}},[_c('div',{staticClass:"support",class:{
      '_align-left': _setup.align === 'left',
      '_align-right': _setup.align === 'right',
      '_align-top': _vm.freemium?.supporters.positionAlign === 'top',
      '_align-bottom': _vm.freemium?.supporters.positionAlign === 'bottom',
      '_is-adding': _setup.isAdding,
      '_first-is-removing': _setup.isFirstCardRemoving,
    }},_vm._l((_setup.supporters),function(supporter){return _c('div',{key:supporter.createdAt,staticClass:"support__card"},[_c(_setup.Supporter,{attrs:{"emoji":supporter.emoji,"name":supporter.nickname}})],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }