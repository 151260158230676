import elementLocaleEn from 'element-ui/lib/locale/lang/en'
import elementLocaleRu from 'element-ui/lib/locale/lang/ru-RU'
import elementLocale from 'element-ui/src/locale'

import { i18n } from '@/i18n'

export const setLocale = (locale) => {
  i18n.locale = locale
  localStorage.setItem('locale', locale)

  // for element-ui components
  if (locale === 'ru') {
    elementLocale.use(elementLocaleRu)
  }
  else {
    elementLocale.use(elementLocaleEn)
  }
}
